const bio = () => {
  return [
    {
      name: "Hans Brombacher",
      title: "Chief Executive Officer",
      bio: "Hans is a highly accomplished entrepreneur with an impressive academic background, holding both a BSc and a Masters degree in the engineering field. With two decades of experience, he has carved a niche for himself in the mezzanine finance space, establishing multiple greenfield organizations with substantial multi-million rand valuations. Notably, Hans leads each venture with great proficiency, adopting a hands-on approach that enables him to navigate the businesses to success from the forefront. His remarkable track record and proactive leadership make him a standout figure in the entrepreneurial realm.",
    },
    {
      name: "Bianca Redgard",
      title: "Chief Operations Officer",
      bio: "Bianca is a distinguished professional, armed with a BSc in Genetics and an MBA degree. Her tenure of 10 years with the company has been marked by exceptional accomplishments, particularly in effectively managing a portfolio of over 12,000 assets. Beyond her impressive managerial abilities, Bianca's outstanding interpersonal skills have fostered a culture of growth and development within the organization. Her commitment to nurturing talent from within has led to remarkable staff retention rates, solidifying her reputation as a true leader and mentor.",
    },
    {
      name: "Savanna Brombacher",
      title: "Chief Financial Officer",
      bio: "With a strong academic background comprising a BSc Actuarial and Financial Engineering degree, as well as a BSc Honours in Statistics, Savanna assumes a leadership role in our finance division. Her responsibilities encompass efficient cash flow management, diligent tracking of key performance indicators (KPIs), and utilizing AI-powered financial modeling to project and monitor the company's performance. Moreover, Savanna is committed to meeting the needs of our investors, ensuring their interests are well-catered for throughout the company's financial journey.",
    },
    {
      name: "Bryan Brombacher",
      title: "Sales Director",
      bio: "Bryan is a seasoned Executive Sales Director with a wealth of expertise spanning two decades in the logistics industry across Southern Africa. His extensive experience and in-depth knowledge have been instrumental in securing sales amounting to over R1.4 billion, solidifying his reputation as a highly accomplished leader in driving sales success within the region's logistics sector.",
    },
    {
      name: "Carika Nel",
      title: "Operations & Collections Manager",
      bio: "Carika assumes a prominent role as the leader of our operations division, with a specific focus on overseeing our collections team. Her unwavering drive and boundless energy foster robust client relationships, leading to a profound understanding of the intricacies involved in owning and operating a logistics company. With her expertise and dedication, she plays a pivotal role in ensuring the seamless functioning of our operations and reinforcing our commitment to excellence in the industry.",
    },
  ];
};
export { bio };
