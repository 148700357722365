//import * as bootstrap from 'bootstrap'
import { bio } from "./bio.js";
import { legal } from "./legal.js";
import { runPostSubmitAnimation } from "./animations.js";

document.addEventListener("DOMContentLoaded", function () {
  bindEvents();
});

let name = document.getElementById("name-mdl");
let title = document.getElementById("title-mdl");
let bioText = document.getElementById("bio-mdl");

function loadBio(i) {
  let b = bio();
  name.innerText = b[i].name;
  title.innerText = b[i].title;
  bioText.innerText = b[i].bio;
  modalResize();
}

function loadLegal(i) {
  let l = legal;
  name.innerHTML = l[i].title;
  title.innerText = "";
  bioText.innerHTML = l[i].body;
  modalResize(true);
}

function modalResize(large = false) {
  let modal = document.getElementById("bioModal");
  modal.classList.remove("modal-lg");
  if (large) modal.classList.add("modal-lg");
}

function bindEvents() {
  document.querySelectorAll(".legal-link-privacy").forEach((link) => {
    link.addEventListener("click", function (e) {
      e.preventDefault();
      loadLegal(0);
    });
  });

  document.querySelectorAll(".legal-link-terms").forEach((link) => {
    link.addEventListener("click", function (e) {
      e.preventDefault();
      loadLegal(1);
    });
  });

  document.getElementById("bio-0").addEventListener("click", function (e) {
    e.preventDefault();
    loadBio(0);
  });
  document.getElementById("bio-1").addEventListener("click", function (e) {
    e.preventDefault();
    loadBio(1);
  });
  document.getElementById("bio-2").addEventListener("click", function (e) {
    e.preventDefault();
    loadBio(2);
  });
  document.getElementById("bio-3").addEventListener("click", function (e) {
    e.preventDefault();
    loadBio(3);
  });
  document.getElementById("bio-4").addEventListener("click", function (e) {
    e.preventDefault();
    loadBio(4);
  });
}

export default loadBio;

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
  "use strict";
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll(".needs-validation");
  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach(function (form) {
    form.addEventListener(
      "submit",
      function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
          form.classList.add("was-validated");

          let name = document.getElementById("nameInput").value;
          let surName = document.getElementById("surnameInput").value;
          let contact = document.getElementById("contactInput").value;
          let email = document.getElementById("emailInput").value;
          let message = document.getElementById("messageInput").value;

          const payload = {
            compname: "WRTSA",
            email,
            message,
            name: `${name} ${surName}`,
            number: contact,
            type: "mail",
            cell: contact,
            From: email,
            MailFrom: email,
            WebURL: "wrtsa.co.za",
            site: "We Rent Trucks",
            web: "We Rent Trucks",
            suburb: "N/A",
            subject: "New contact request",
            title: "New contact request",
          };

          fetch("https://genericweb.nectarsoft.co.za/api/Comms/EmailTokens", {
            //mode: "no-cors", //net::ERR_ABORTED 415 (Unsupported Media Type)
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          })
            .then((response) => {
              console.log(response);
              runPostSubmitAnimation(form);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        form.classList.add("was-validated");
      },
      false
    );
  });
})();
