gsap.from("#hero-heading", {
  y: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
  delay: 0.5,
});

gsap.from("#hero-subheading", {
  x: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
  delay: 1,
});

gsap.from("#hero-cta", {
  y: 50,
  opacity: 0,
  duration: 2,
  ease: "power2.inOut",
  delay: 2,
});

gsap.fromTo(
  ".hero",
  {
    backgroundSize: "110%",
  },
  {
    backgroundSize: "125%",
    duration: 8,
    pinReparent: true, // moves to documentElement during pin
    pinSpacing: false,
    pinType: "transform", // or "fixed"
    pinnedContainer: ".hero",
  }
);

// gsap.from("#whatWeDo", {
//   scrollTrigger: {
//     toggleActions: "play pause resume reverse",
//     trigger: "#whatWeDo",
//     start: "bottom bottom",
//     replay: true,
//     reverse: true,
//   },
//   x: -100,
//   opacity: 0,
//   duration: 1,
//   ease: "power2.inOut",
// });

let count = document.getElementById("trucksFinanced");
let counter = 0;

//This is the interval variable
let beginCounting;

function startCounting(count) {
  clearInterval(beginCounting);
  beginCounting = setInterval(() => {
    if (counter < 223) {
      counter += 1;
      count.innerText = counter;
    }
  }, 10);
}

function clearCounting() {
  clearInterval(beginCounting);
  counter = 0;
  count.innerText = counter;
}

function startCounter() {
  clearCounting();
  startCounting(count);
}

gsap.from(".number", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: "#whatWeDo",
    start: "bottom bottom",
    onEnter: startCounter,
  },
  x: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
});

// gsap.from("#ourProcess", {
//   scrollTrigger: {
//     toggleActions: "play pause resume reverse",
//     trigger: "#ourProcess",
//     start: "top center",
//   },
//   y: -100,
//   opacity: 0,
//   duration: 1,
//   ease: "power2.inOut",
// });

gsap.from("#process-step-1", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: "#process-step-1",
    start: "top bottom",
  },
  x: -100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
});

gsap.from("#process-step-2", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: "#process-step-1",
    start: "top bottom",
  },
  y: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
});

gsap.from("#process-step-3", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: "#process-step-1",
    start: "top bottom",
  },
  x: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
});

gsap.from(".meet-container", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: ".meet-container",
    start: "top center",
  },
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
  stagger: 0.3,
});

// gsap.from("#hearWhatOthersSay", {
//   scrollTrigger: {
//     toggleActions: "play pause resume reverse",
//     trigger: "#hearWhatOthersSay",
//   },
//   y: -100,
//   opacity: 0,
//   duration: 1,
//   ease: "power2.inOut",
// });

gsap.from(".testimonial-container", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: ".testimonial-container",
  },
  y: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
});

// gsap.from("#speakToUs", {
//   scrollTrigger: {
//     toggleActions: "play pause resume reverse",
//     trigger: "#speakToUs",
//   },
//   y: -100,
//   opacity: 0,
//   duration: 1,
//   ease: "power2.inOut",
// });

gsap.from(".contact-container", {
  scrollTrigger: {
    toggleActions: "play pause resume reverse",
    trigger: ".contact-container",
  },
  y: 100,
  opacity: 0,
  duration: 1,
  ease: "power2.inOut",
});

const runPostSubmitAnimation = (form) => {
  form.classList.remove("was-validated");
  form.reset();
  gsap.to(".contact-active", {
    y: -100,
    opacity: 0,
    duration: 1.5,
    ease: "power2.inOut",
    delay: 0.5,
    onComplete: () => {
      form.classList.add("hidden");
      document.getElementById("speakToUs").classList.add("hide");
      document.getElementById("speakingToUs").classList.remove("hide");
      gsap.from("#speakingToUs", {
        y: 100,
        opacity: 0,
        duration: 1,
        ease: "power2.inOut",
      });
    },
  });
};

// export runPostSubmitAnimation
export { runPostSubmitAnimation };
